import React, {Component} from "react";
import "./GetInTouch.css";
import {AppContext, AppContextType} from "../../AppContext";
import {BulletList} from "../../components/bullet-list/BulletList";
import firebase from "firebase";
import {Helmet} from "react-helmet";

type Props = {};

type State = {
    contatti: any;
};

class GetInTouch extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/contatti');
        dbRef.on('value', snapshot => {
            this.setState({contatti: snapshot.val()})
        });
    }

    render() {
        return (
            <div id={'contact-page'}>

                {/* SEO */}
                <Helmet>
                    {this.state?.contatti?.seo?.meta_titolo?._value ?
                        <title>{this.state?.contatti?.seo?.meta_titolo?._value + " - " + process.env.REACT_APP_TITLE}</title> :
                        <title>{process.env.REACT_APP_TITLE}</title>}
                    <meta name='description' content={this.state?.contatti?.seo?.meta_descrizione?._value as string}/>
                    <meta name='keywords' content={this.state?.contatti?.seo?.meta_keywords?._value}/>
                </Helmet>

                {/* Header Contatti*/}
                <div className={'bullet-lists'}
                     data-aos="fade-in"
                     data-aos-once="true">
                    {this.state?.contatti?.titolo && this.state?.contatti?.titolo?._value?
                        <BulletList title={this.state?.contatti?.titolo?._value}
                                                   description={this.state?.contatti?.descrizione?._value}
                                                   bulletList={[]}
                                                   gallery={[]}></BulletList>

                        : null
                    }
                </div>


                {/* Colonne Contatti*/}
                <div className={'flex padded line-height_1-6 flex-direction_row-column gap_40px'}>
                    <div className={'flex_1 font-18 text-align_left'} dangerouslySetInnerHTML={{__html: this.state?.contatti?.colonna_1?._value}}>

                    </div>
                    <div className={'flex_1 font-18 text-align_left'} dangerouslySetInnerHTML={{__html: this.state?.contatti?.colonna_2?._value}}>

                    </div>
                </div>

            </div>
        );
    }


}

GetInTouch.contextType = AppContext;
export default GetInTouch;
