import React from "react";
import './News-Detail.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import WithDialog from "../../ui/dialog/WithDialog";
import ScrollContainer from "react-indiana-drag-scroll";
import NewsCell from "../../news/news-cell/News-Cell";
import {ImagesSlider} from "../../ui/images-slider/ImagesSlider";
import moment from "moment";
import 'moment/locale/it'
import {Helmet} from "react-helmet";
import firebase from "firebase/app";
import {withNavigate} from "../../ui/WithNavigate";
import Footer from "../../footer/Footer";

interface NewsDetailProp extends DialogPropTypes {
    navigate: any;
    params:any;
    key:any;
}

class NewsDetail extends React.Component<NewsDetailProp, any> {

    plainDescriptionText = 'ciao bel';

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
        const newsKey = this.props.params.key;

        const dbRef = firebase.database().ref(ctx.basePath + '/news/_list/' + newsKey);
        dbRef.on('value', snapshot => {
            let news = snapshot.val();
            this.plainDescriptionText = news?.sottotitolo?._value;
            this.setState({news: news})
        });

        const dbRefLabels = firebase.database().ref(ctx.basePath + '/impostazioni/etichette/news');
        dbRefLabels.on('value', snapshot => {
            console.log(snapshot.val())
            this.setState({labels: snapshot.val()})
        });


    }



    render() {

        return <div id={'news-detail-page'}>

            {/* SEO */}
            <Helmet>
                {this.state?.news?._description?._value ?
                    <title>{this.state?.news?._description?._value +" - "+ process.env.REACT_APP_TITLE}</title> :
                    <title>{process.env.REACT_APP_TITLE}</title>}
                <meta name='description' content={this.plainDescriptionText as string} />
                <meta name='keywords' content={this.state?.news?.tags?._value} />
            </Helmet>


            <div className={'news-detail-div padded'}  data-aos="fade-in" data-aos-once="true">

                    <div className={'news-cell-div'}>

                        <div className={'info'}>
                            <p className={'font-14 subtitle news-date'}>{moment(this.state.news?.data?._value).format('DD/MM/YYYY')}</p>
                            <p className={'title font-36-600'}>{this.state.news?._description?._value}</p>
                            <p className={'font-16-500 subtitle'} dangerouslySetInnerHTML=
                                {{__html:this.state.news?.sottotitolo?._value}}></p>
                        </div>

                        {/*Image*/}
                        <div className={'news-media-container padded-vertical-less-less'}>
                            <div id={'news-media'} data-aos="fade-up" data-aos-once="true"
                                 className={'img-container-div img-container-main-home-div kreosoft-pointer'}>
                                {
                                    this.state.news?.gallery?._list && Object.keys(this.state.news?.gallery?._list).length > 0 ?
                                        <ImagesSlider autoplay={true} images={Object.keys(this.state.news?.gallery?._list).
                                        filter((s:string)=> s!="0").map(key=> this.state.news?.gallery?._list[key])}>
                                        </ImagesSlider> :null
                                }
                            </div>
                        </div>

                        {/* Sezioni */}
                        <div className={'sections-container-div padded'}>
                        {
                            this.state.news?.sezioni?._list? Object.keys(this.state.news?.sezioni?._list)
                                .filter((s:string)=> s!="0").sort((a,b) => this.state?.news?.sezioni?._list[a]?._order >this.state?.news?.sezioni?._list[b]?._order? 1: -1).map(
                                    s=>
                                        <div key={'s_'+s} className={'flex flex-direction_column gap_16px'}>

                                            <p className={'section-title font-24-600 text-align_left'}  dangerouslySetInnerHTML=
                                                {{__html:this.state.news?.sezioni._list[s]?.titolo_sezione?._value}}></p>
                                            <p key={'sp_'+s} className={'section-description font-16-500 text-align_left line-height_1-6'}  dangerouslySetInnerHTML=
                                                {{__html:this.state.news?.sezioni._list[s]?.descrizione_sezione?._value}}>
                                            </p>


                                            {
                                                this.state.news?.sezioni._list[s]?.gallery_sezione && this.state.news?.sezioni._list[s]?.gallery_sezione?._list && Object.keys(this.state.news?.sezioni._list[s]?.gallery_sezione?._list)?.length>1?
                                                    <div className={'cursor-grab'}>
                                                        <ScrollContainer className="gallery-detail">

                                                            {
                                                                Object.keys(this.state.news?.sezioni._list[s]?.gallery_sezione?._list||{})?.
                                                                filter((key:string)=> key!="0")?.
                                                                map((key:string)=>this.state.news?.sezioni._list[s]?.gallery_sezione?._list[key])?.sort((item1, item2)=> item1?._order > item2?.order? -1:1).
                                                                map((immagine:any, index:number) => {
                                                                    return <img data-aos="fade-in" data-aos-once="true" data-aos-delay={150*(index??0)} key={'phco'+ index} className="gallery-item-detail" src={immagine?.immagine?._value} ></img>
                                                                })
                                                            }

                                                        </ScrollContainer>
                                                    </div>
                                                    : null
                                            }


                                        </div>
                                ) : ""
                        }
                        </div>
                        
                    </div>
            </div>


            {/* News correlate*/}
{/*            {
                this.state?.news?.news_correlate?._list && Object.keys( this.state?.news?.news_correlate?._list) &&
                Object.keys( this.state?.news?.news_correlate?._list)?.length>1?
                    <div id={'related-news'} className={'news-background padded'}>

                        <p className={'font-40-600 title-label'}>{this.state.labels?.news_correlati_titolo?._value}</p>

                        <p className={'font-14 subtitle-label'}
                           dangerouslySetInnerHTML={{__html:this.state.labels?.news_correlati_descrizione?._value}}></p>

                        <div className={'software-list-container-div'}>
                            {
                                this.state?.news?.news_correlate?._list ?
                                    Object.keys(this.state.news?.news_correlate._list)
                                        .filter(nK=>nK!="0")
                                        .sort((key1, key2)=> this.state.news?.news_correlate?._list[key1]?._order > this.state.news?.news_correlate?._list[key2]?.order? -1:1)
                                        .map((nK: any, index: number) => {
                                        let news = this.state?.news?.news_correlate?._list[nK];
                                        return (
                                            <NewsCell
                                                key={'new_' +news?._id}
                                                index={index}
                                                newsKey={news?._linkedId}>
                                            </NewsCell>
                                        );
                                    }) : "" }
                        </div>

                    </div> :
                    null
            }*/}


        </div>



    }

}

NewsDetail.contextType = AppContext;
export default withNavigate(WithDialog(NewsDetail));

