import React from "react";
import './ScreenshotsGallery.css';
import {goTo} from "../../Utils";
import {AppContext, AppContextType} from "../../AppContext";
import ScrollContainer from "react-indiana-drag-scroll";

class Props {
    title: string = '';
    description: string = '';
    headerButton?: any;

    gallery: any[] = [];
}

class State {
}


export class ScreenshotsGallery extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);

        this.state = {}
    }

    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
    }


    render() {

        return <div className={'screenshot-gallery padded-vertical'}>
            <div className={'padded'}>

                <div className={'flex flex-direction_row width_100'}>
                    <div id={"title"} className={'flex_1 font-36-600 text-align_left'}>
                        <p>{this.props?.title}</p>
                    </div>

                    <div className={'flex_1 flex justify-content_end'}>

                        {
                            this.props?.headerButton && this.props?.headerButton?.etichetta?
                                <button className={'btn accent-btn'}
                                        onClick={(event) => {
                                            goTo(this.props?.headerButton?.link || '', event)
                                        }}>
                                    <p className={'font-16'}>
                                        {this.props?.headerButton?.etichetta}
                                    </p>
                                </button> : null
                        }

                    </div>
                </div>
            </div>

            <div className={'screenshot-gallery-subtitle font-22 padded'}
                 dangerouslySetInnerHTML={{__html: this.props?.description}}>

            </div>


            {/* Gallery */}
            {
                <ScrollContainer className="screen-gallery">
                    {Object.keys(this.props?.gallery || {})
                        .filter((key: string) => this.props?.gallery[key as any]?._id != 0)
                        ?.map((key: string) => this.props?.gallery[key as any])
                        ?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1).map((item: any, index: number) => (
                            <div data-aos="fade"
                                 data-aos-once="true"
                                 data-aos-delay={100 * index} className={"screen-gallery-item"}
                                 key={'screen-gallery-' + index}>

                                <div className={'screenshot-img-container'}>
                                    <img src={item?.immagine?._value}/>
                                </div>


                                <div className={'didascalia-container'}>
                                    <b
                                        dangerouslySetInnerHTML={{__html: item?.titolo_immagine?._value}}
                                    />
                                    <p
                                        className={"didascalia-p line-height_1-6"}
                                        dangerouslySetInnerHTML={{
                                            __html: item?.descrizione_immagine?._value
                                        }}
                                    />
                                </div>
                            </div>
                        )
                    )
                    }
                </ScrollContainer>
            }

        </div>
    }
}

ScreenshotsGallery.contextType = AppContext;

