import React from "react";
import './NewsGrid.css';
import {goTo} from "../../Utils";
import {AppContext, AppContextType} from "../../AppContext";
import ScrollContainer from "react-indiana-drag-scroll";
import NewsCell from "../../news/news-cell/News-Cell";

class Props {
    title: string = '';
    headerButton?: any;

    gallery: any[] = [];
}

class State {
}


export class NewsGrid extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);

        this.state = {}
    }

    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
    }


    render() {

        return this.props?.title ?
                <div id="news" className="gallery-container padded padded-vertical">

                    <div className={'flex flex-direction_row features-container-div'}>

                        <div className={'font-36-600 flex_1 flex align-left'}
                             dangerouslySetInnerHTML={{__html: this.props?.title}}>

                        </div>

                        {
                            this.props?.headerButton && this.props.headerButton?.etichetta?
                                <div className={'text-align_right'}>
                                    <div className={'btn text-align_center'}
                                         onClick={(event) => {
                                             goTo(this.props?.headerButton?.link, event)
                                         }}>
                                        <p className={'font-14-500'}>
                                            {this.props?.headerButton?.etichetta}
                                        </p>
                                    </div>
                                </div>:null
                        }


                    </div>

                    <div className="news-grid padded-vertical-less">
                        {Object.keys(this.props?.gallery || {}).filter((key: string) => this.props?.gallery[key as any]?._id != 0)?.map((key: string) => this.props?.gallery[key as any])?.sort((a: any, b: any) => a?._order > b?._order ? 1 : -1).map(
                            (item: any, index: number) => (
                                <NewsCell
                                    key={'gl-item-' + index + Date.now()}
                                    index={index}
                                    newsKey={item?._linkedId?.toString()}>
                                </NewsCell>
                            )
                        )}
                    </div>
                </div> : <></>
        }

}

NewsGrid.contextType = AppContext;

