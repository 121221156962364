import React, {RefObject} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Lazy, Navigation, Pagination, EffectFade, Autoplay} from "swiper";
import "./ImagesSlider.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/lazy";
import "swiper/css/effect-fade";

class ImagesSliderProps {
    images?: any[] = [];
    cellSlider? = false;
    autoplay?: any = false;
    excludeYoutube? = false;
}

class ImagesSliderState {
    images: any[] = [];
}

export class ImagesSlider extends React.Component<ImagesSliderProps, ImagesSliderState> {
    wrapperRef: RefObject<any>;

    constructor(props: any) {
        super(props);
        this.state = {
            images:  this.props.excludeYoutube?
                ([].concat(Object.values(props.images)??[]).filter((item:any) => item?._id != 0 && !item?.immagine?._mediaType?.startsWith('youtube') && !item?.immagine?._mediaType?.startsWith('video')).sort((a:any,b:any)=>{
                    return (a?._order > b?._order)?1:-1
                })) :
                ([].concat(Object.values(props.images)??[]).filter((item:any) => item?._id != 0).sort((a:any,b:any)=>{
                    return (a?._order > b?._order)?1:-1
                }))
        };
        this.wrapperRef = React.createRef();
    }


    async componentDidMount() {

    }

    componentDidUpdate(prevProps: Readonly<ImagesSliderProps>, prevState: Readonly<ImagesSliderState>, snapshot?: any) {
        if(prevProps?.images && this.props?.images && prevProps?.images != this.props?.images){
            this.setState({
                images:  this.props.excludeYoutube?
                    ([].concat(Object.values(this.props.images as any)??[]).filter((item:any) => item?._id != 0 && !item?.immagine?._mediaType?.startsWith('youtube') && !item?.immagine?._mediaType?.startsWith('video')).sort((a:any,b:any)=>{
                        return (a?._order > b?._order)?1:-1
                    })) :
                    ([].concat(Object.values(this.props.images as any)??[]).filter((item:any) => item?._id != 0).sort((a:any,b:any)=>{
                        return (a?._order > b?._order)?1:-1
                    }))
            },()=>{
                //console.log(this.state.images)
            });
        }
    }

    getYoutubeVideoID(youtubeLink: string){
        const videoID = youtubeLink?.split('/').pop();
        return videoID;
    }

    render() {

        const pagination = this.props?.cellSlider ? false : {
            clickable: true,
        };

        setTimeout(()=>{
            if(this.props.autoplay && this.wrapperRef.current){
                this.wrapperRef.current.lastChild.swiper.autoplay.start();
            } else if(this.wrapperRef.current){
                this.wrapperRef.current.lastChild.swiper.autoplay.stop();
            }
        }, 300)

        return (
            <div ref={this.wrapperRef} className="images-slider">
                <Swiper

                    lazy={{
                        loadPrevNext: true
                    }}
                    pagination={pagination}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: true
                    }}
                    modules={[Lazy, Pagination, Navigation, EffectFade, Autoplay]}

                    parallax={true}
                >

                    {this.state?.images && this.state?.images?.length > 0 ?
                        this.state?.images?.map((img: any, index: number) => {
                            return (
                                img.immagine && img.immagine._value ?
                                    <SwiperSlide key={"img_slide_" + index + img?._id}>

                                        {img.immagine?._mediaType?.startsWith('youtube')?

                                            <div data-aos="fade-in"
                                                 data-aos-once="true"
                                                 className="swiper-lazy">
                                                <div className={'iframe-youtube-cover-overlay'}></div>
                                                <iframe className={'iframe-youtube-cover'}
                                                        src={img.immagine._value+'?controls=0&showinfo=0&rel=0&autoplay=1&loop=1&mute=1&playlist=' + this.getYoutubeVideoID(img.immagine._value)}>

                                                </iframe>
                                            </div>

                                            :
                                            (
                                                img.immagine?._mediaType?.startsWith('video')?
                                                    <div data-aos="fade-in"
                                                         data-aos-once="true"
                                                         className="swiper-lazy"

                                                    >
                                                        <video key={'uploaded-video-' +index} autoPlay={true} loop={true} muted={true}
                                                               className={'uploaded-video-display'}>
                                                            <source src={img?.immagine?._value}
                                                                    type={img?.immagine?._mediaType}/>
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>

                                                    :

                                                    <div data-aos="fade-in"
                                                         data-aos-once="true"
                                                         className="swiper-lazy"
                                                         style={{
                                                             backgroundImage: 'url(' + img.immagine?._value + ')'
                                                         }
                                                         }
                                                    ></div>
                                            )

                                        }

                                        <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                                    </SwiperSlide>
                                    : null


                            );
                        }) : null}
                </Swiper>
            </div>
        );
    }
}
