import React from "react";
import './Popup.css';
import {AppContext, AppContextType} from "../../AppContext";
import {ImagesSlider} from "../../ui/images-slider/ImagesSlider";

class Props {
    title: string = '';
    description: string = '';
    linkButton?: any;
    labelButton?: any;
    gallery?: any[] = [];
    popupID?: number;
}

class State {
    showPopup = false;
}


export class Popup extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);

    }

    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
        const popupClosed = localStorage.getItem('closed_alert_' + this.props?.popupID)
        if (!popupClosed) {
            this.setState({
                showPopup: true
            })
        }
    }

    hidePopup() {
        localStorage.setItem('closed_alert_' + this.props?.popupID, JSON.stringify(true));
        this.setState({
            showPopup: false
        })
    }

    render() {

        return <>

            {
                this.state?.showPopup?
                    <div className={'overlay-popup'} onClick={() => this.hidePopup()}>

                        <div className={'popup-container gap_16px flex flex-direction_column'}>
                            <i className={'icon-chiudi'} onClick={() => this.hidePopup()}></i>

                            {this.props?.title?
                                <div className={'flex flex-direction_column text-align_left gap_16px'}>
                                    <p className={'font-22-600'}>{this.props?.title}</p>
                                    <p className={'font-14'} dangerouslySetInnerHTML={{__html:this.props?.description}}></p>
                                </div>
                                : null
                            }


                            <ImagesSlider autoplay={false}
                                          images={this.props?.gallery}></ImagesSlider>

                            {
                                this.props?.labelButton?
                                    <div className={'flex justify-content_end'}>
                                        <button className={'btn'}>
                                            <a href={this.props?.linkButton} target={this.props.linkButton?.startsWith('http')? '_blank' : '_self'} >
                                                {this.props?.labelButton}
                                            </a>
                                        </button>
                                    </div> : null
                            }

                        </div>

                    </div>
                    : null
            }


        </>;

    }
}

Popup.contextType = AppContext;

