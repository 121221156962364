// context/todoContext.tsx
import * as React from 'react';

export interface IApp {
    basePath: string;
}

export type AppContextType = {
    appContext: IApp;
    updateAppContext?: (basePath: string) => void;
};

export const AppContext = React.createContext<AppContextType | null>(null);

const AppProvider: React.FC<React.ReactNode> = () => {
    const [appContext] = React.useState<IApp>({
        basePath:""
    });
    const updateAppContext = (basePath: string) => {
        appContext.basePath=basePath;
    };
    return <AppContext.Provider value={{ appContext , updateAppContext }}> </AppContext.Provider>;
};

export default AppProvider;
