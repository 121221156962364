import React, {Component} from "react";
import {AppContext, AppContextType} from "../AppContext";
import './News.css';
import firebase from "firebase";
import NewsCell from "./news-cell/News-Cell";
import {goTo} from "../Utils";
import {ImagesSlider} from "../ui/images-slider/ImagesSlider";
import Footer from "../footer/Footer";
import {Helmet} from "react-helmet";

type Props = {
    data?: any;
};

type State = {
    news: any;
};

class News extends Component<Props, State> {

    constructor(props: Props) {
        super(props);

    }

    componentDidMount() {

        const ctx = (this.context as AppContextType).appContext;

        const dbRef = firebase.database().ref(ctx.basePath + '/pagine/news');
        dbRef.on('value', snapshot => {
            this.setState({news: snapshot.val()})
        });
    }

    render() {

        return <>

            {this.state?.news?.titolo?._value ?
                <div>

                    {/* SEO */}
                    <Helmet>
                        {this.state?.news?.seo?.meta_titolo?._value ?
                            <title>{this.state?.news?.seo?.meta_titolo?._value + " - " + process.env.REACT_APP_TITLE}</title> :
                            <title>{process.env.REACT_APP_TITLE}</title>}
                        <meta name='description' content={this.state?.news?.seo?.meta_descrizione?._value as string}/>
                        <meta name='keywords' content={this.state?.news?.seo?.meta_keywords?._value}/>
                    </Helmet>

                    <div className={'news-container-div padded text-align-left'} data-aos="fade-in"
                         data-aos-once="true">

                        <div className={'font-36-600 news-title'}
                             dangerouslySetInnerHTML={{__html: this.state?.news?.titolo?._value}}>
                        </div>

                        <div className={'font-22 news-subtitle'}
                             dangerouslySetInnerHTML={{__html: this.state?.news?.sottotitolo?._value}}>
                        </div>

                        <div className={'news-grid'}>

                            {
                                this.state?.news?.carosello_news?._list ?
                                    Object.keys(this.state?.news?.carosello_news?._list)?.filter(k => k != "0")?.map((k: any, index: number) => {
                                        return (
                                            <NewsCell
                                                key={'sc' + k}
                                                index={k}
                                                newsKey={this.state?.news?.carosello_news?._list[k]?._linkedId?.toString()}>

                                            </NewsCell>
                                        );
                                    }) :
                                    null
                            }

                        </div>

                    </div>



                </div>
                : null}
        </>

    }
}

News.contextType = AppContext;
export default News;
