import { Context, createContext } from "react";

export const dialogContext: Context<DialogPropTypes> = createContext({
    openDialog: (args: {
        component: React.ReactNode;
        title: string;
        okCallback: (data:any) => void;
        cancelCallback?: () => void;
        width?: DialogWidthType;
        okText?: string;
        cancelText?: string;
        classes?:string;
    }) => {
    },
    closeDialog: () => {}
});

export type DialogWidthType = "xl" | "lg" | "md" | "sm";

export type OpenDialogType = (args: {
    component: React.ReactNode;
    title: string;
    okCallback: (data:any) => void;
    cancelCallback?: () => void;
    width?: DialogWidthType;
    okText?: string;
    cancelText?: string;
    classes?: string;
}) => void;

export interface DialogPropTypes {
    openDialog: OpenDialogType;
    closeDialog: EmptyFunctionType;
}

export type EmptyFunctionType = (data?:any) => void;
