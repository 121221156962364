import React from "react";
import './News-Cell.css';
import {DialogPropTypes} from "../../ui/dialog/DialogContext";
import {AppContext, AppContextType} from "../../AppContext";
import WithDialog from "../../ui/dialog/WithDialog";
import firebase from "firebase/app";
import moment from "moment";
import {withNavigate} from "../../ui/WithNavigate";


interface NewsCellProp extends DialogPropTypes {
    navigate?: any;
    params?: any;
    newsKey: any;
    index: number;
}

class NewsCell extends React.Component<NewsCellProp, any> {

    constructor(props: any) {
        super(props);

        this.state = {}
    }


    async componentDidMount() {
        const ctx = (this.context as AppContextType).appContext;
        const newsKey = this.props.newsKey;

        const dbRef = firebase.database().ref(ctx.basePath + '/news/_list/' + newsKey);
        dbRef.on('value', snapshot => {
            let val=snapshot.val();
            this.setState({news: snapshot.val()})
        });


    }

    goToNews() {
        this.props.navigate('/news/' + this.state.news._id)
    }

    render() {

        return <div onClick={() => this.goToNews()}
                    className={'news-cell-div'} data-aos="fade-in" data-aos-once="true"
                    data-aos-delay={150 * (this.props.index ?? 0)}>


            <div className={'info'}>
                <p className={'news-date font-16'}>{moment(this.state.news?.data?._value).format('DD/MM/YYYY')}</p>
                <p className={'font-24-600'}>
                    {this.state.news?._description?._value}
                </p>
                <p className={'font-16'}>
                    {this.state.news?.sottotitolo?._value}
                </p>
                <p id={'read-more-container '}>
                    <span className={'read-more font-16'}>
                    Leggi di più
                    </span>
                </p>

            </div>

            {
                this.state?.news?.gallery && this.state?.news?.gallery?._list ?
                    <div className={'img'}
                         style={{backgroundImage: `url(${Object.keys(this.state?.news?.gallery?._list).map(key => this.state?.news?.gallery?._list[+key])?.filter((i: any) => i._id != 0 && i.immagine?._mediaType?.startsWith('image'))[0]?.immagine?._value})`}}></div>
                    : null
            }


        </div>

    }

}

NewsCell.contextType = AppContext;
export default  withNavigate(WithDialog(NewsCell));

